<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Socio - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3" fill>
                <b-tab title="Datos Personales" active>
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Tipo de Documento :">
                          <b-form-select v-model="partner.document_type" :options="document_type"></b-form-select>
                        </b-form-group>
                        <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Nro Documento :">
                          <b-input-group>
                            <b-form-input v-model="partner.document_number" class="form-control" ></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary"  @click="SearchPartner"><b-icon icon="search"></b-icon></b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Nombres:" required>
                          <b-form-input type="text" v-model="partner.name"></b-form-input>
                          <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Fecha Nacimiento:">
                          <b-form-input type="date" v-model="partner.birth_date"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Estado Civil :">
                          <b-form-select v-model="partner.civil_status" :options="civil_status"></b-form-select>
                        </b-form-group>
                      </b-col>
          
                      <b-col md="2">
                        <b-form-group label="Sexo:">
                          <b-form-select v-model="partner.sex" :options="sex"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Celular :">
                          <b-form-input type="text" v-model="partner.cell_phone"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Telefono :">
                          <b-form-input type="text" v-model="partner.phone"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group label="Profesión:">
                          <b-form-input v-model="partner.profession"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Nivel de Instrucción:">
                          <b-form-select v-model="partner.level_instruction" :options="level_instruction"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group label="Ubigeo :">
                        <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100"  v-model="mubigee" :options="ubigee"></v-select>
                        </b-form-group>
                      </b-col>
                
                      <b-col md="6">
                        <b-form-group label="Dirección :">
                          <b-form-input type="text" v-model="partner.address" ></b-form-input>
                        </b-form-group>
                      </b-col>
              
                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                      </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Datos de Asociación">
                  <b-row>
                      <b-col md="4">
                        <b-form-group label="Email :">
                          <b-form-input type="email" v-model="partner.email" ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Clave :">
                          <b-form-input type="password" v-model="partner.password"></b-form-input>
                        </b-form-group>
                      </b-col>
                    <b-col md="2">
                      <b-form-group label="Fecha Registro :">
                        <b-form-input type="date" v-model="partner.registration_date"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Nivel :">
                        <b-form-select  v-model="partner.level" :options="level"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado :">
                        <b-form-select  v-model="partner.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Ficha:">
                        <b-form-file
                          accept="application/pdf"
                          v-model="file"
                          :state="Boolean(file)"
                          @change="onFileChange"
                          placeholder="Selecciona un archivo"
                          drop-placeholder="Arrastre el archivo aqui"
                        ></b-form-file>
                        <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                      </b-form-group>
                    </b-col>
                    
                    <b-col md="12"></b-col>
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Datos Laborales">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Nombre de Empresa:">
                        <b-form-input type="text" v-model="partner.company_name" ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Fecha de Ingreso :">
                        <b-form-input type="date" v-model="partner.date_admission"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Ingreso Mensual Neto :">
                        <b-form-input type="number" step="any" class="text-right"  v-model="partner.net_monthly_income"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Cargo :">
                        <b-form-input  v-model="partner.position"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Area de Trabajo :">
                        <b-form-input  v-model="partner.work_area"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Teléfono:">
                        <b-form-input  v-model="partner.company_phone"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Modalidad de Contratación:">
                        <b-form-select  v-model="partner.modality_contracting" :options="modality_contracting"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Centro de Trabajo:">
                        <b-form-select v-model="partner.workplace" :options="workplace"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Ubigeo:">
                      <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100"  v-model="mubigeeb" :options="ubigee"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-form-group label="Dirección:">
                        <b-form-input  v-model="partner.company_address"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                    </b-col>
                    
                 
                  </b-row>
                </b-tab>
                <b-tab title="Familiares y Beneficiarios">
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Tipo de Documento :">
                          <b-form-select v-model="partner_family.document_type" :options="document_type"></b-form-select>
                        </b-form-group>
                        <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                      </b-col>

                      <b-col md="2">
                        <b-form-group label="Nro Documento :">
                          <b-input-group>
                            <b-form-input v-model="partner_family.document_number" class="form-control" ></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary"  @click="SearchPartnerFamily"><b-icon icon="search"></b-icon></b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small v-if="errors.document_number_family" class="form-text text-danger" >{{error_document_number_family}}</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group label="Nombres:">
                          <b-form-input type="text" v-model="partner_family.name"></b-form-input>
                          <small v-if="errors.name_family" class="form-text text-danger" >Ingrese un nombre</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Celular:">
                          <b-form-input type="text" v-model="partner_family.cell_phone"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Teléfono:">
                          <b-form-input type="text" v-model="partner_family.phone"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Tipo Beneficiario:">
                          <b-form-select v-model="partner_family.type_beneficiary" :options="type_beneficiary"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Parentesco:">
                          <b-form-select v-model="partner_family.relationship" :options="relationship"></b-form-select>
                          <small v-if="errors.relationship_family" class="form-text text-danger" >Seleccione un parentesco</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Estado:">
                          <b-form-select v-model="partner_family.state" :options="state"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label=".">
                          <b-button class="form-control" @click="ValidateFamily" type="button">Guardar</b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive ">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th width="5%" class="text-center">#</th>
                                <th width="12%" class="text-center">Documento</th>
                                <th width="35%" class="text-center">Nombre</th>
                                <th width="10%" class="text-center">Celular</th>
                                <th width="10%" class="text-center">Tipo Beneficiario</th>
                                <th width="10%" class="text-center">Parentesco</th>
                                <th width="8%" class="text-center">Estado</th>
                                <th width="10%" class="text-center">Acc.</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, it) in partner.partners_family" :key="it">
                              <tr>
                                <td class="text-center">{{ it + 1 }}</td>
                                <td class="text-left">{{ CodeDocumentType(item.document_type) +' - '+ item.document_number }}</td>
                                <td class="text-left"> {{ item.name }}</td>
                                <td class="text-left"> {{ item.phone +" - "+item.cell_phone  }}</td>
                                <td class="text-left"> {{ NameTypeBeneficiary(item.type_beneficiary) }}</td>
                                <td class="text-left"> {{ NameRelationship(item.relationship) }}</td>
                                <td class="text-center">
                                  <b-badge v-if="item.state == 0" variant="danger">Inactivo</b-badge>
                                  <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                                  <b-badge v-if="item.state == 3" variant="warning">Fallecido</b-badge>
                                  <b-badge v-if="item.state == 4" variant="info">Retirado</b-badge>
                                </td>
                                <td class="text-center">
                                  <b-button variant="danger" @click="DeleteBeneficiary(it)" type="button" size="sm"><i class="fas fa-trash"></i></b-button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>

                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                      </b-col>


                    </b-row>

                </b-tab>
              </b-tabs>
            </b-form>


            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "UsuarioAdd",
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module:'Partner',
      partner: {
	      id_partner:'',
	      document_type:'1',
	      document_number:'',
	      name:'',
	      user:'',
	      email:'',
	      password:'',
	      token:'',
	      birth_date:'',
	      sex:'',
	      civil_status:'',
	      profession:'',
	      level_instruction:'',
	      ubigee:'',
	      address:'',
	      phone:'',
	      cell_phone:'',
	      sending_payment:'',
	      company_name:'',
	      date_admission:'',
	      net_monthly_income:'',
	      position:'',
	      work_area:'',
	      company_phone:'',
	      modality_contracting:'',
        company_ubigeo:'',
	      company_address:'',
	      workplace:'',
	      registration_date:moment(new Date()).local().format("YYYY-MM-DD"),
	      level:'1',
	      state:'1',
        file:'',
        partners_family:[],
      },
      partner_family: {
	      id_partner_family:'',
	      document_type:'1',
	      document_number:'',
	      name:'',
	      phone:'',
	      cell_phone:'',
	      type_beneficiary:'2',
	      relationship:'',
	      state:'1',
      },
      
      mubigee:null,
      ubigee: [],
      mubigeeb:null,
      file:null,
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      sex: [
        {value: '' , text : 'Seleccione un sexo'},
        {value: 'H' , text : 'Hombre'},
        {value: 'M' , text : 'Mujer'},
      ],
      civil_status:[
        {value:"", text:"-- Seleccione --"},
        {value:"1", text:"Soltero"},
        {value:"2", text:"Casado"},
        {value:"3", text:"Viudo"},
        {value:"4", text:"Divorciado"},
        {value:"5", text:"Conviviente"},
      ],
      level_instruction:[
        {value:"", text:"-- Seleccione --"},
        {value:1,text: "Primaria"},
        {value:2,text: "Secundaria"},
        {value:3,text: "Medio-Superior"},
        {value:4,text: "Superior"},
      ],
      sending_payment:[
        {value:1,text: "Si"},
        {value:0,text: "No"},
      ],
      level:[
        {value:1,text: "Nivel 1"},
        {value:2,text: "Nivel 2"},
        {value:3,text: "Nivel 3"},
      ],
      modality_contracting:[
        {value:"", text:"-- Seleccione --"},
        {value:1,text:"Nombrado"},
        {value:2,text:"Contrato Indef."},
        {value:3,text:"Contrato Pl. Fijo"},
        {value:4,text:"CAS"},
        {value:5,text:"Cesante"},
      ],
      workplace:[
        {value:"", text:"-- Seleccione --"},
        {value:1, text: "Hospital"},
        {value:2, text: "Red de Salud"},
        {value:3, text: "Diresa"},
        {value:4, text: "Cesantes"},                          
      ],  
      type_beneficiary:[
        {value:1, text: "Titular Directo"},
        {value:2, text: "Familiar"},                        
      ], 
      relationship:[
        {value:"", text:"-- Seleccione --"},
        {value:"1", text:"ESPOSO (A)"},
        {value:"2", text:"HIJO (A)"},
        {value:"3", text:"PADRE"},
        {value:"4", text:"MADRE"},
        {value:"5", text:"TIO (A)"},
        {value:"6", text:"SOBRINO (A)"},
        {value:"7", text:"ABUELO (A)"},
        {value:"8", text:"HERMANO (A)"},
        {value:"9", text:"CONYUGE"},
        {value:"10", text:"PRIMO (A)"},
        {value:"11", text:"OTROS"},                       
      ],                            
      state:[
        {value: 0 , text : 'Inactivo'},
        {value: 1 , text : 'Activo'},
        {value: 3 , text : 'Fallecido'},
        {value: 4 , text : 'Retirado'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,

        document_type_family: false,
        document_number_family: false,
        name_family: false,
        relationship_family:false,
      },
      error_document_number :'',
      validate: false,
      error_document_number_family :'',
      validate_family: false,
    };
  },
  mounted() {
  this.ListUbigeos();
  },
  methods: {
    SearchPartner,
    AddPartner,
    ListUbigeos,
    Validate,

    SearchPartnerFamily,
    ValidateFamily,

    CodeDocumentType,
    NameTypeBeneficiary,
    NameRelationship,
    DeleteBeneficiary,

     onFileChange,
  },

  computed: {
    
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function onFileChange(e) {
  this.partner.file = e.target.files[0];
}

function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}
function NameTypeBeneficiary(code) {
  let name = "";
  switch (code) {
    case "1": name = "Titular Directo"; break;
    case "2": name = "Familiar"; break;
  }
  return name;
}
function NameRelationship(code) {
  let name = "";
  switch (code) {
    case "1": name = "ESPOSO (A)"; break;
    case "2": name = "HIJO (A)"; break;
    case "3": name = "PADRE"; break;
    case "4": name = "MADRE"; break;
    case "5": name = "TIO (A)"; break;
    case "6": name = "SOBRINO (A)"; break;
    case "7": name = "ABUELO (A)"; break;
    case "8": name = "HERMANO (A)"; break;
    case "9": name = "CONYUGE"; break;
    case "10": name = "PRIMO (A)"; break;
    case "11": name = "OTROS"; break;
  }
  return name;
}

function ListUbigeos() {
 this.ubigee = [];
//  if (this.partner.country != "PE") {
//    this.mubigee = null;
//    return false;
//  }
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigee.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
    })

}


function SearchPartner() {

  let me = this;
  if (me.partner.document_type == 1) {
      if (me.partner.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.partner.document_number).then((data) => {
          if (data.status == 200) {
            me.partner.name = data.razon_social;
            me.partner.address = data.direccion;
          }else{
            me.partner.name = '';
            me.partner.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.partner.document_type == 6) {
      if (me.partner.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.partner.document_number).then((data) => {
          if (data.status == 200) {
            me.partner.name = data.razon_social;
            me.partner.address = data.direccion;
          }else{
            me.partner.name = '';
            me.partner.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

function AddPartner() {

  let me = this;
  me.partner.ubigee = me.mubigee != null ?  me.mubigee.value : '';
  me.partner.company_ubigeo = me.mubigeeb != null ?  me.mubigeeb.value : '';


  let url = me.url_base + "partner/add";
  let data = new FormData();
  data.append("id_partner", this.partner.id_partner);
  data.append("document_type", this.partner.document_type);
  data.append("document_number", this.partner.document_number);
  data.append("name", this.partner.name);
  data.append("user", this.partner.user);
  data.append("email", this.partner.email);
  data.append("password", this.partner.password);
  data.append("token", this.partner.token);
  data.append("birth_date", this.partner.birth_date);
  data.append("sex", this.partner.sex);
  data.append("civil_status", this.partner.civil_status);
  data.append("profession", this.partner.profession);
  data.append("level_instruction", this.partner.level_instruction);
  data.append("ubigee", this.partner.ubigee);
  data.append("address", this.partner.address);
  data.append("phone", this.partner.phone);
  data.append("cell_phone", this.partner.cell_phone);
  data.append("sending_payment", this.partner.sending_payment);
  data.append("company_name", this.partner.company_name);
  data.append("date_admission", this.partner.date_admission);
  data.append("net_monthly_income", this.partner.net_monthly_income);
  data.append("position", this.partner.position);
  data.append("work_area", this.partner.work_area);
  data.append("company_phone", this.partner.company_phone);
  data.append("modality_contracting", this.partner.modality_contracting);
  data.append("company_ubigeo", this.partner.company_ubigeo);
  data.append("company_address", this.partner.company_address);
  data.append("workplace", this.partner.workplace);
  data.append("registration_date", this.partner.registration_date);
  data.append("level", this.partner.level);
  data.append("file", this.partner.file);
  data.append("state", this.partner.state);
  data.append("partners_family", JSON.stringify(this.partner.partners_family));


  // let data = me.partner;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 201) {

        me.partner.id_partner = '';
	      me.partner.document_type = '1';
	      me.partner.document_number = '';
	      me.partner.name = '';
	      me.partner.user = '';
	      me.partner.email = '';
	      me.partner.password = '';
	      me.partner.token = '';
	      me.partner.birth_date = '';
	      me.partner.sex = '';
	      me.partner.civil_status = '';
	      me.partner.profession = '';
	      me.partner.level_instruction = '';
	      me.partner.ubigee = '';
	      me.partner.address = '';
	      me.partner.phone = '';
	      me.partner.cell_phone = '';
	      me.partner.sending_payment = '';
	      me.partner.company_name = '';
	      me.partner.date_admission = '';
	      me.partner.net_monthly_income = '';
	      me.partner.position = '';
	      me.partner.work_area = '';
	      me.partner.company_phone = '';
	      me.partner.modality_contracting = '';
        me.partner.company_ubigeo = '';
	      me.partner.company_address = '';
	      me.partner.workplace = '';
	      me.partner.registration_date = moment(new Date()).local().format("YYYY-MM-DD"),
	      me.partner.level = '1';
	      me.partner.state = '1';
        me.partner.file = '';
        me.partner.partners_family = [];

        me.mubigee = null;
        me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {
  this.validate = false;
  if (this.partner.document_type == 1) {
    this.errors.document_number = this.partner.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.partner.document_type == 6){
    this.errors.document_number = this.partner.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.partner.document_type == 4){
    this.errors.document_number = this.partner.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.partner.document_type == 7){
    this.errors.document_number = this.partner.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.partner.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.document_type = this.partner.document_type.length == 0 ? true : false;
  this.errors.name = this.partner.name.length == 0 ? true : false;

  if (this.errors.document_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el socio?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddPartner();
      }
    });

  }

}






function SearchPartnerFamily() {

  let me = this;
  if (me.partner_family.document_type == 1) {
      if (me.partner_family.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.partner_family.document_number).then((data) => {
          if (data.status == 200) {
            me.partner_family.name = data.razon_social;
            me.partner_family.address = data.direccion;
          }else{
            me.partner_family.name = '';
            me.partner_family.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.partner_family.document_type == 6) {
      if (me.partner_family.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.partner_family.document_number).then((data) => {
          if (data.status == 200) {
            me.partner_family.name = data.razon_social;
            me.partner_family.address = data.direccion;
          }else{
            me.partner_family.name = '';
            me.partner_family.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

function ValidateFamily() {


  this.validate = false;
  if (this.partner_family.document_type == 1) {
    this.errors.document_number = this.partner_family.document_number.length != 8 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.partner_family.document_type == 6){
    this.errors.document_number = this.partner_family.document_number.length != 11 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.partner_family.document_type == 4){
    this.errors.document_number = this.partner_family.document_number.length != 12 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.partner_family.document_type == 7){
    this.errors.document_number = this.partner_family.document_number.length != 12 ? true : false;
    this.error_document_number_family = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.partner_family.document_number.length == 0 ? true : false;
    this.error_document_number_family = 'Ingrese un nro de documento';
  }

  this.errors.document_type_family = this.partner_family.document_type.length == 0 ? true : false;
  this.errors.document_number_family = this.partner_family.document_number.length == 0 ? true : false;
  this.errors.name_family = this.partner_family.name.length == 0 ? true : false;
  this.errors.relationship_family = this.partner_family.relationship.length == 0 ? true : false;

  if (this.errors.document_type_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.relationship_family) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  this.partner.partners_family.push({
    id_partner_family:this.partner_family.id_partner_family,
    document_type:this.partner_family.document_type,
    document_number:this.partner_family.document_number,
    name:this.partner_family.name,
    phone:this.partner_family.phone,
    cell_phone:this.partner_family.cell_phone,
    type_beneficiary:this.partner_family.type_beneficiary,
    relationship:this.partner_family.relationship,
    state:this.partner_family.state,
  });
  this.partner_family.id_partner_family = '';
  this.partner_family.document_type = '1';
  this.partner_family.document_number = '';
  this.partner_family.name = '';
  this.partner_family.phone = '';
  this.partner_family.cell_phone = '';
  this.partner_family.type_beneficiary = '2';
  this.partner_family.relationship = '';
  this.partner_family.state = '1';

}

function DeleteBeneficiary(index) {
  this.partner.partners_family.splice(index, 1);
}
</script>
